import { useEffect, useRef, useState, useCallback } from "react"
import { useTransform, useElementScroll } from "framer-motion"
import { useSwipeable } from "react-swipeable"

const dimensions = { top: 0, right: 0, bottom: 0, left: 0 }

const getIsInViewPort = elem => {
  const rect = elem.getBoundingClientRect()
  return rect.top + window.pageYOffset + rect.height > window.pageYOffset
}

/**
 * Updates the target dimensions for the animation element on window resize.
 *
 * To prevent calling the function too many times during resize (especially on mobile),
 * the hook checks if the element is in viewport and uses some extra checks to
 * ensure it is updated once it is returning to the viewport.
 *
 */
const useUpdateDimensionsOnWindowResize = updateDimensionsFn => {
  const animationElementRef = useRef(null)
  const dimensionsUpdatedRef = useRef(true)

  useEffect(
    function windowResizeUpdateEffect() {
      const animationElement = animationElementRef.current
      const listener = () => {
        if (!getIsInViewPort(animationElement)) {
          dimensionsUpdatedRef.current = false
          return
        }
        updateDimensionsFn()
        dimensionsUpdatedRef.current = true
      }
      window.addEventListener("resize", listener)
      return () => window.removeEventListener("resize", listener)
    },
    [updateDimensionsFn]
  )

  useEffect(() => {
    const animationElem = animationElementRef.current
    const listener = () => {
      if (getIsInViewPort(animationElem) && !dimensionsUpdatedRef.current) {
        updateDimensionsFn()
        dimensionsUpdatedRef.current = true
      }
    }
    window.addEventListener("scroll", listener)
    return () => window.removeEventListener("scroll", listener)
  }, [updateDimensionsFn])

  return [animationElementRef]
}

const useActivateScrollContainer = (scrollContainerRef, scrollYProgress) => {
  const activateScrollContainer = useCallback(() => {
    const scrollContainerElement = scrollContainerRef.current
    if (scrollYProgress.get() >= 1 && window.pageYOffset <= 0) {
      scrollContainerElement.scrollTop = scrollContainerElement.scrollTop - 10
    }
  }, [])

  const swipeHandlers = useSwipeable({
    trackTouch: true,
    onSwipedDown: () => {
      activateScrollContainer()
    },
  })

  useEffect(() => {
    swipeHandlers.ref(document)
  })

  useEffect(() => {
    const listener = event => {
      const dirIsUp = Math.sign(event.deltaY) === -1
      if (dirIsUp) {
        activateScrollContainer()
      }
    }
    window.addEventListener("wheel", listener)
    return () => window.removeEventListener("wheel", listener)
  }, [activateScrollContainer])
}

export default function useHeroAnimation() {
  const targetElementRef = useRef(null)
  const scrollContainerRef = useRef(null)
  const { scrollYProgress } = useElementScroll(scrollContainerRef)
  const [targetDimensions, setTargetDimensions] = useState(dimensions)
  useActivateScrollContainer(scrollContainerRef, scrollYProgress)

  const updateAnimationFactorDimensions = useCallback(() => {
    const targetElement = targetElementRef.current.imageRef.current
    const rect = targetElement.getBoundingClientRect()
    const newDimensions = {
      top: rect.top + window.pageYOffset,
      left: rect.left,
      bottom: document.documentElement.clientHeight - rect.top - rect.height - window.pageYOffset, // prettier-ignore
      right: rect.left,
    }
    setTargetDimensions(newDimensions)
  }, [])

  const [animationElementRef] = useUpdateDimensionsOnWindowResize(
    updateAnimationFactorDimensions
  )

  useEffect(() => {
    const scrollContainerElem = scrollContainerRef.current
    if (window.pageYOffset > 0) {
      scrollContainerElem.scrollTop = window.innerHeight + 500
    } else {
      document.body.style.overflow = "hidden"
    }
  }, [])

  useEffect(() => {
    const animationElem = animationElementRef.current
    const videoElem = animationElem.getElementsByTagName("video")[0]
    return scrollYProgress.onChange(value => {
      if (value < 1) {
        document.body.style.overflow = "hidden"
        if (videoElem && videoElem.paused) {
          videoElem.play()
        }
      } else {
        document.body.style.overflow = ""
        if (videoElem && !videoElem.paused) {
          videoElem.pause()
        }
      }
    })
  }, [])

  useEffect(() => {
    const scrollContainerElem = scrollContainerRef.current
    const listener = event => {
      const scrollYProgressValue = scrollYProgress.get()
      if (event.isComposing || event.keyCode === 229)
        return; // prettier-ignore
      if (event.key === "ArrowUp") {
        if (scrollYProgressValue >= 1 && window.pageYOffset <= 0)
          scrollContainerElem.scrollTop = scrollContainerElem.scrollTop - 8
        if (scrollYProgressValue < 1)
          scrollContainerElem.scrollTop = scrollContainerElem.scrollTop - 100
      }
      if (event.key === "ArrowDown" && scrollYProgressValue < 1)
        scrollContainerElem.scrollTop = scrollContainerElem.scrollTop + 100
    }
    document.addEventListener("keydown", listener)
    return () => document.removeEventListener("keydown", listener)
  }, [])

  const animationOutputStyle = {
    top: useTransform(scrollYProgress, [0, 1], [0, targetDimensions.top]),
    right: useTransform(scrollYProgress, [0, 1], [0, targetDimensions.right]),
    bottom: useTransform(scrollYProgress, [0, 1], [0, targetDimensions.bottom]),
    left: useTransform(scrollYProgress, [0, 1], [0, targetDimensions.left]),
    filter: useTransform(scrollYProgress, value => value < 1 ? "grayscale(0%)" : "grayscale(100%)"), // prettier-ignore
    position: useTransform(scrollYProgress, value => value === 0 ? "fixed" : "absolute"), // prettier-ignore
    borderRadius: useTransform(scrollYProgress, value => value === 0 ? "0px" : "16px") // prettier-ignore
  }

  const contentContainerStyle = {
    opacity: useTransform(scrollYProgress, [0.3, 1], [0, 1]),
  }

  const scrollContainerStyle = {
    zIndex: 5000,
    visibility: useTransform(scrollYProgress, (value => value < 1 ? "visible" : "hidden")) // prettier-ignore
  }

  const otherContentStyles = {
    opacity: useTransform(scrollYProgress, [0.1, 0.3], [1, 0]),
    visibility: useTransform(scrollYProgress, value => value < 0.3 ? "visible" : "hidden") // prettier-ignore
  }

  const actions = {
    onLoadRefImg: () => updateAnimationFactorDimensions(),
    onClickArrowDown: () => scrollContainerRef.current.scrollTop = window.innerHeight + 500, // prettier-ignore
    onClickPageTitle: () => (scrollContainerRef.current.scrollTop = 0),
  }

  return {
    targetElementRef,
    scrollContainerRef,
    animationOutputStyle,
    otherContentStyles,
    contentContainerStyle,
    scrollContainerStyle,
    actions,
    animationElementRef,
  }
}
