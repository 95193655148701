import { useMemo, useRef, useEffect, useState } from "react"
import visit from "unist-util-visit"

export const useGetIntraPageNavIsVisible = getRefElementPosition => {
  const ref = useRef()
  const [navIsVisible, setNavIsVisible] = useState(false)

  useEffect(() => {
    const position = getRefElementPosition(ref.current)
    const onScroll = () => setNavIsVisible(window.scrollY > position)
    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return [ref, navIsVisible]
}

export const extractSectionHeadersData = mdxAST => {
  const result = []
  visit(mdxAST, ["jsx", "html"], node => {
    const data = /<SectionHeader[^>]*subtitle="([^"]*)"[^>]*title="([^"]*)"[^>]*\/>/.exec(node.value) // prettier-ignore
    if (data?.length) {
      const [, subtitle, title] = data
      result.push({ subtitle, title })
    }
  })
  return result
}

export const useExtractSectionHeadersData = mdxAST => {
  return useMemo(() => extractSectionHeadersData(mdxAST), [])
}

export const useGetNodeFilePublicUrl = (fileName = null, allFiles) => {
  return useMemo(() => {
    if (!fileName) return null
    const pdfFileNode = (allFiles?.nodes?.filter(node => {
      const nodeFileName = (node?.name ?? "") + (node?.ext ?? "")
      return fileName === nodeFileName
    }) ?? [])[0]
    return pdfFileNode?.publicURL
  }, [])
}
